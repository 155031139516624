<template>
<div>
  <div :class="{
    'container':
    !data.gadgetTabs,
  }">  
      <h2 
        v-if="data.title"
        class="pt-6 pb-4 text-2xl font-semibold"
      >
        {{ data.title }}
        <span class="block mt-2 h-1 w-8 rounded bg-orange"></span>
      </h2>
    <div class="tablist-wrapper relative">
      <div class="w-6 h-full bg-gradient-to-r from-purple5-transparent to-purple-5 absolute z-1 right-0 md:hidden"></div>
      <div
        v-if="data.tabs[0].tabTitle"
        role="tablist"
        class="flex overflow-y-hidden overflow-x-auto scrolling-touch whitespace-no-wrap no-scrollbar"
        :class="{
          'md:table bg-white-alpha-75 rounded-t-1 relative z-1':
          data.gadgetTabs
        }"
      >
        <button
          v-for="(tab, index) in data.tabs"
          :key="tab.id"
          :data-cy="`tabbed-components-button-${index}`"
          role="tab"
          v-bind:id="'tab_' + getFullName(tab.tabTitle)"
          v-bind:aria-selected="index === currentTab ? 'true' : 'false'"
          v-bind:aria-controls="getFullName(tab.tabTitle)"
          v-on:click.prevent="
            tabClick(index, tab.tabTitle, tab.heroTitle);
            $generalClick({
              category: 'Page Interaction',
              label: 'Tab_' + getFullName(tab.tabTitle),
            });
          "
          v-bind:class="{
            'font-semibold text-purple-dark relative inline-block no-underline hover:text-purple p-4 text-md text-center -outline-offset-2':
              (currentTab === index) && (!data.gadgetTabs),
            'flex-grow font-semibold bg-white rounded-t-1 relative inline-block no-underline md:px-5 py-2 text-md text-center -outline-offset-2':
              (currentTab === index) && (data.gadgetTabs),
            'relative inline-block no-underline text-black hover:text-purple p-4 text-md text-center -outline-offset-2':
              (currentTab !== index) && (!data.gadgetTabs),
            'flex-grow relative inline-block no-underline text-black md:px-5 py-2 text-md text-center -outline-offset-2':
              (currentTab !== index) && (data.gadgetTabs),
          }"
          :data-cms-text="`tabbedComponents.${index}.tabTitle`"
        >{{ tab.tabTitle }}
          <span v-if="!data.gadgetTabs"
          class="absolute bottom-0 w-full left-0 border-t-2"
          :class="{
            'border-t-4 border-blue-darker rounded' : currentTab === index,
            'border-t-2 border-b-1 border-b-transparent border-purple-lighter' : currentTab !== index}"
          ></span>
        </button>
      </div>
    </div>
  </div>

      <div class="flex" 
        :class="{
          'pb-5':
          !data.gadgetTabs,
          'bg-white rounded-b-1 md:rounded md:rounded-tl-none':
          data.gadgetTabs
        }"
        :style="data.tabs[0].minHeight? `min-height:${data.tabs[0].minHeight}px` : ``">
        <div
          v-for="(tab, index) in data.tabs"
          :key="`${tab.tabTitle}-tabbed-panel-${index}`"
          :data-cy="`${tab.tabTitle}-tabbed-panel-${index}`"
          v-bind:id="getFullName(tab.tabTitle)"
          role="tabpanel"
          class="w-full"
          v-bind:aria-labelledby="'tab_' + getFullName(tab.tabTitle)"
          v-show="currentTab === index"
        >
          <div 
            :class="{
              'pt-5':
                !data.gadgetTabs,
              'container-waves-bg bg-gradient-to-b from-transparent to-purple-5' :
                data.hasCurves
            }"
            >
            <div :class="{
              'container':
              !data.gadgetTabs,
            }">  
              <div
                v-for="(com, idx) in tab.componentsTopSection"
                :key="`${tab.tabTitle}-component-${idx}`"
                :data-cms-parent="`tabbedComponents.${index}.components.${idx}`"
              >
                <component
                  :is="Object.keys(com)[0]"
                  :data="com[Object.keys(com)[0]]"
                ></component>
              </div>
            </div>
          </div>

          <div
            v-for="(com, idx) in tab.componentsBottomSection"
            :key="`${tab.tabTitle}-component-${idx}`"
            :data-cms-parent="`tabbedComponents.${index}.components.${idx}`"
          >
            <component
              :is="Object.keys(com)[0]"
              :data="com[Object.keys(com)[0]]"
            ></component>
          </div>

        </div>
      </div>  

</div>
</template>

<script>
import HelperFunctions from "@/HelperFunctions";
import components from "@/components.js";

export default {
  components: components,
  props: { data: Object },
  data() {
    return {
      currentTab: 0
    };
  },
  methods: {
    tabClick: function (idx, tabTitle, heroTitle) {
      if(this.data.gadgetTabs) {
        if(heroTitle) {
          this.$root.$emit("heroTabClick", heroTitle);
        }
        this.$root.$emit("eatTabSwitch", idx);
        this.$ga4GeneralClick({
          event: 'tab',
          module: 'search gadget',
          action: 'click',
          content_display: tabTitle
        });
      }
      else {
        this.$ga4GeneralClick({
          event: 'tab',
          module: '',
          action: 'click',
          content_display: tabTitle
        });
      }
      this.currentTab = idx;
    },
    getFullName: function (str) {
      return HelperFunctions.removeSpaces(str);
    },
  },
  beforeMount() {
    if(this.data.gadgetTabs) {
      this.$root.$on("eatTabSwitch", (idx) => {
        this.currentTab = idx;
      });
    }
  }
};
</script>

<style lang="scss">
.curvetop::before {
  content: "";
  display: block;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1440 263.46"><defs><linearGradient id="a" x1="-.07" y1="1939.49" x2=".82" y2="1939.49" gradientTransform="matrix(1440 0 0 -201.48 265 390921.02)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="%23f5f4f5"/><stop offset="1" stop-color="%23adabb4"/></linearGradient><linearGradient id="b" x1="-.18" y1="1939.55" x2=".82" y2="1939.55" gradientTransform="matrix(1440 0 0 -192.81 265 374119.54)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="%23f8bf99"/><stop offset="1" stop-color="%23ee6000"/></linearGradient><linearGradient id="c" x1="-.18" y1="1939.3" x2=".81" y2="1939.3" gradientTransform="matrix(1440 0 0 -230.97 265 448035.26)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="%2384808f"/><stop offset="1" stop-color="%23322c44"/></linearGradient></defs><path fill="%23fcfbfc" d="M0 54c309.89 181.71 817.2 226.2 1220 92.12 83.81-27.92 157.32-61.59 220-99.67V.44H0Z" style="fill-rule:evenodd"/><path d="M1272 163.81C850.82 304 319.1 255.27 0 62v63.61c370.08 168.27 858.1 179.84 1262.74 49.27 64.36-22.1 123.62-47.48 177.26-75.48v-5.47c-50.54 25.98-106.63 49.43-168 69.88Z" style="fill:url(%23a);fill-rule:evenodd"/><path data-name="Path" d="M1272 165.81c61.41-20.45 117.51-43.9 168-69.88V53.45c-62.64 38-136.16 71.75-220 99.67C817.24 287.2 309.9 242.71 0 61v3c319.12 193.27 850.87 242 1272 101.81Z" style="fill:url(%23b);fill-rule:evenodd"/><path data-name="Path" d="M1170.86 137.07C783.72 268.09 295.49 223.52 0 44.35V61c309.89 181.71 817.2 226.2 1220 92.12 83.81-27.92 157.32-61.59 220-99.67V0c-70.85 53.6-161.06 100.49-269.14 137.07Z" style="fill:url(%23c);fill-rule:evenodd"/></svg>');
  transform: translateY(-1px);
  height: 260px;
  width: 345vw;
  margin: -160px 0 0 -130vw;

  @screen md {
    width: 185vw;
    margin-left: -40vw;
  }

  @screen lg {
    width: 100%;
    height: 18.5vw;
    max-height: 260px;
    margin: 0;
  }
}

.container-waves-bg::after {
  content: "";
  display: block;
  width: 100%;
  height: 8vw;
  max-height: 90px;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1440 95"><path fill="%23fcfbfc" d="M225,0Q90,0,0,23V95H1440V0Q1169,92,953,92C737,92,360,0,225,0Z"/></svg>');
}
</style>