var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
    'container':
    !_vm.data.gadgetTabs,
  }},[(_vm.data.title)?_c('h2',{staticClass:"pt-6 pb-4 text-2xl font-semibold"},[_vm._v("\n        "+_vm._s(_vm.data.title)+"\n        "),_c('span',{staticClass:"block mt-2 h-1 w-8 rounded bg-orange"})]):_vm._e(),_c('div',{staticClass:"tablist-wrapper relative"},[_c('div',{staticClass:"w-6 h-full bg-gradient-to-r from-purple5-transparent to-purple-5 absolute z-1 right-0 md:hidden"}),(_vm.data.tabs[0].tabTitle)?_c('div',{staticClass:"flex overflow-y-hidden overflow-x-auto scrolling-touch whitespace-no-wrap no-scrollbar",class:{
          'md:table bg-white-alpha-75 rounded-t-1 relative z-1':
          _vm.data.gadgetTabs
        },attrs:{"role":"tablist"}},_vm._l((_vm.data.tabs),function(tab,index){return _c('button',{key:tab.id,class:{
            'font-semibold text-purple-dark relative inline-block no-underline hover:text-purple p-4 text-md text-center -outline-offset-2':
              (_vm.currentTab === index) && (!_vm.data.gadgetTabs),
            'flex-grow font-semibold bg-white rounded-t-1 relative inline-block no-underline md:px-5 py-2 text-md text-center -outline-offset-2':
              (_vm.currentTab === index) && (_vm.data.gadgetTabs),
            'relative inline-block no-underline text-black hover:text-purple p-4 text-md text-center -outline-offset-2':
              (_vm.currentTab !== index) && (!_vm.data.gadgetTabs),
            'flex-grow relative inline-block no-underline text-black md:px-5 py-2 text-md text-center -outline-offset-2':
              (_vm.currentTab !== index) && (_vm.data.gadgetTabs),
          },attrs:{"data-cy":("tabbed-components-button-" + index),"role":"tab","id":'tab_' + _vm.getFullName(tab.tabTitle),"aria-selected":index === _vm.currentTab ? 'true' : 'false',"aria-controls":_vm.getFullName(tab.tabTitle),"data-cms-text":("tabbedComponents." + index + ".tabTitle")},on:{"click":function($event){$event.preventDefault();_vm.tabClick(index, tab.tabTitle, tab.heroTitle);
            _vm.$generalClick({
              category: 'Page Interaction',
              label: 'Tab_' + _vm.getFullName(tab.tabTitle),
            });}}},[_vm._v(_vm._s(tab.tabTitle)+"\n          "),(!_vm.data.gadgetTabs)?_c('span',{staticClass:"absolute bottom-0 w-full left-0 border-t-2",class:{
            'border-t-4 border-blue-darker rounded' : _vm.currentTab === index,
            'border-t-2 border-b-1 border-b-transparent border-purple-lighter' : _vm.currentTab !== index}}):_vm._e()])}),0):_vm._e()])]),_c('div',{staticClass:"flex",class:{
          'pb-5':
          !_vm.data.gadgetTabs,
          'bg-white rounded-b-1 md:rounded md:rounded-tl-none':
          _vm.data.gadgetTabs
        },style:(_vm.data.tabs[0].minHeight? ("min-height:" + (_vm.data.tabs[0].minHeight) + "px") : "")},_vm._l((_vm.data.tabs),function(tab,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === index),expression:"currentTab === index"}],key:((tab.tabTitle) + "-tabbed-panel-" + index),staticClass:"w-full",attrs:{"data-cy":((tab.tabTitle) + "-tabbed-panel-" + index),"id":_vm.getFullName(tab.tabTitle),"role":"tabpanel","aria-labelledby":'tab_' + _vm.getFullName(tab.tabTitle)}},[_c('div',{class:{
              'pt-5':
                !_vm.data.gadgetTabs,
              'container-waves-bg bg-gradient-to-b from-transparent to-purple-5' :
                _vm.data.hasCurves
            }},[_c('div',{class:{
              'container':
              !_vm.data.gadgetTabs,
            }},_vm._l((tab.componentsTopSection),function(com,idx){return _c('div',{key:((tab.tabTitle) + "-component-" + idx),attrs:{"data-cms-parent":("tabbedComponents." + index + ".components." + idx)}},[_c(Object.keys(com)[0],{tag:"component",attrs:{"data":com[Object.keys(com)[0]]}})],1)}),0)]),_vm._l((tab.componentsBottomSection),function(com,idx){return _c('div',{key:((tab.tabTitle) + "-component-" + idx),attrs:{"data-cms-parent":("tabbedComponents." + index + ".components." + idx)}},[_c(Object.keys(com)[0],{tag:"component",attrs:{"data":com[Object.keys(com)[0]]}})],1)})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }